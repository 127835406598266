import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Container, Row, Col, Nav, Tab } from "react-bootstrap";
import PageWrapper from "../components/PageWrapper";
import {Section, Title, Text, Box, Button, MainTitle} from "../components/Core";
import { graphql, useStaticQuery } from "gatsby";
import Seo from "../components/Seo";
import { navigate } from "gatsby";
import {getProperty} from "../utils/helperFn";
import FAQimg from "../assets/image/png/fttp_text.png"

const NavStyled = styled(Nav)`
  border-radius: 10px;
  border: 1px solid #eae9f2;
  background-color: #f7f7fb;
  padding-top: 15px;
  padding-bottom: 15px;

  a {
    color: ${({ theme }) => theme.colors.dark} !important;
    &:hover,
    &:active,
    &:visited {
      color: ${({ theme }) => theme.colors.secondary} !important;
    }
  }
`;

const Support = () => {

    const data = useStaticQuery(graphql`
    query HelpAndSupportQuery {
       allStrapiSupportCategories(sort: {fields: id}) {
        nodes {
          slug
          name
          active
        }
      }
      allStrapiSupportQAs {
        nodes {
          support_category {
            slug
          }
          answer
          answer_1
          question
          strapiId
        }
      }
        strapiPageHeadings(slug: {eq: "faqs"}) {
            h1
        }
    }
    `)

    const [selectedCategory, setSelectedCategory] = useState('')
    const supportQAs = data.allStrapiSupportQAs.nodes
    const supportCategories = data.allStrapiSupportCategories.nodes.filter(category => (category.active))
    const headingsData = getProperty(data, 'strapiPageHeadings');

    const renderTabPanel = (category) => {
        const sortedSupportQAs =
            (selectedCategory === 'FAQ-wayleaves') ? supportQAs
                    .filter(QA => QA.support_category.slug === category.slug)
                    .sort((a, b) => a.strapiId - b.strapiId) :
                supportQAs
                    .filter(QA => QA.support_category.slug === category.slug)

            return sortedSupportQAs.map(QA => (
                <Box className="mb-5" key={QA.id}>
                    <Title variant="card" mb={3} fontSize="24px">
                        {QA.question}
                    </Title>
                    <Text variant="small" className="text-left" dangerouslySetInnerHTML={{__html: QA.answer}}></Text>
                </Box>
            ))
    }

    useEffect(() => {
        setSelectedCategory(supportCategories[0].slug)
        renderTabPanel(supportCategories[0].slug)
    }, [])

    return (
        <>
            <Seo page="support" />
            <PageWrapper footerDark>
                <Section className="pb-0">
                    <div className="pt-5" />
                    <Container>
                        <Row className="justify-content-center text-center">
                            <Col lg="9">
                                <MainTitle variant="hero" style={{ fontSize: '65px' }}>
                                    {headingsData.h1}
                                </MainTitle>
                                <Text>
                                    We’re the fastest growing full-fibre broadband network operator in the UK,
                                    investing millions of pounds in building a brand-new ultrafast network to several
                                    towns and cities across the country. 
                                </Text>
                                <Text>
                                    Find out more about how we do this in our FAQs.
                                </Text>
                            </Col>
                        </Row>
                    </Container>
                </Section>
                <Section>
                    <Container>
                        <Tab.Container id="left-tabs-example" defaultActiveKey="build-faq">
                            <Row>
                                <Col md="4" className="mb-5 mb-md-0">
                                    <NavStyled className="flex-column mr-md-3 p-2">
                                        {
                                            supportCategories.map(category => (
                                                <Nav.Link eventKey={category.slug}
                                                    key={category.slug}
                                                    onClick={() => setSelectedCategory(category.slug)}
                                                    className={category.slug === selectedCategory ? 'selectedTab' : 'regularTab'}
                                                >
                                                    {category.name}
                                                </Nav.Link>
                                            )
                                            )
                                        }
                                    </NavStyled>
                                </Col>
                                <Col md="8">
                                    <Tab.Content>
                                        {
                                            supportCategories.map(category => (
                                                <Tab.Pane 
                                                    eventKey={category.slug}
                                                    key={category.slug}
                                                    className={category.slug === selectedCategory ? 'active' : ''}
                                                >
                                                    <Box>
                                                        {
                                                            renderTabPanel(category)
                                                        }
                                                    </Box>
                                                </Tab.Pane>
                                            ))
                                        }
                                    </Tab.Content>
                                </Col>
                                <Col lg="12">
                                    <br></br>
                                    <br></br>
                                    <Text className="text-center">
                                        <b>Can’t find the answer you need?</b>
                                    </Text>
                                    <Button
                                        style={{
                                            background: 'linear-gradient(90deg, rgba(252,58,76,1) 0%, rgba(206,3,144,1) 100%)',
                                            border: 'none',
                                            width: 'auto',
                                            margin: 'auto',
                                            display: 'block'
                                        }}
                                        className="btn-red hvr-bounce-to-left"
                                        onClick={() => navigate('/contact')}
                                    >
                                        Contact us
                                    </Button>
                                </Col>
                            </Row>
                        </Tab.Container>
                    </Container>
                </Section>
            </PageWrapper>
        </>
    );
};
export default Support
